<section class="cta-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h2>{{ 'partner.distributors' | translate }}</h2>
                <div class="card card-partner col-lg-3">
                    <div class="container">
                        <h4><b>Veterinaria La Hormiga</b></h4>
                        <h6>{{ 'partner.cellphone' | translate }} 350 874 27 59</h6>
                        <h6>San Carlos - Antioquia - Colombia</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>