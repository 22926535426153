<section id="faq" class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'faq.title_part_1' | translate }} <span>{{ 'faq.title_part_2' | translate }}</span></h2>
            <p>{{ 'faq.subtitle' | translate }}</p>
        </div>

        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <h5 class="mb-0">
                                    {{ 'faq.question_1' | translate }} <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">{{ 'faq.answer_1' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h5 class="mb-0">
                                    {{ 'faq.question_2' | translate }} <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">{{ 'faq.answer_2' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h5 class="mb-0">
                                    {{ 'faq.question_3' | translate }}<i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">{{ 'faq.answer_3' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h5 class="mb-0">
                                    {{ 'faq.question_4' | translate }} <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                            <div class="card-body">{{ 'faq.answer_4' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <h5 class="mb-0">
                                    {{ 'faq.question_5' | translate }} <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">{{ 'faq.answer_5' | translate }}
                            </div>
                        </div>
                    </div>


                    <div class="card">
                        <div class="card-header" role="tab" id="headingSix">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <h5 class="mb-0">
                                    {{ 'faq.question_6' | translate }} <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionEx">
                            <div class="card-body">{{ 'faq.answer_6' | translate }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>