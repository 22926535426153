<section id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'work.title_part_1' | translate }}<span>{{ 'work.title_part_2' | translate }}</span></h2>
            <p>{{ 'work.subtitle' | translate }}</p>
               <div class="icon-pdf">
                        <a title="{{'work.pdf' | translate}}" href="https://firebasestorage.googleapis.com/v0/b/biorganicol.appspot.com/o/assets%2FFICHA%20TE%CC%81CNICA%20ORGANAT.pdf?alt=media&token=9de141d1-fd8b-4016-9185-dad286946314" target="_blank"><img src="assets/img/pdf.png" alt="{{'work.pdf' | translate}}" /><p style="font-size:0.8em;" ></p></a>
                        <br>
                        <p>{{'work.pdf' | translate}}</p>
                </div>

        </div>

        <div class="shorting-menu">
            <button class="filter" data-filter="all">{{ 'work.all_products' | translate }}</button>
            <button class="filter" data-filter=".brand">{{ 'work.products_1' | translate }}</button>
            <button class="filter" data-filter=".design">{{ 'work.products_2' | translate }}</button>
            <button class="filter" data-filter=".graphic">{{ 'work.products_3' | translate }}</button>
            <button class="filter" data-filter=".photoshop">{{ 'work.products_4' | translate }}</button>
            <button class="filter" data-filter=".illustrator">{{ 'work.products_5' | translate }}</button>
        </div>
    </div>

    <div class="container-fluid">
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix brand">
                    <div class="single-work">
                        <img src="assets/img/prod_img_6.jpg" alt="work-img">

                        <div class="work-content">
                            <h3>{{ 'work.subtitle_1' | translate }}</h3>
                            <ul>
                                <!-- <span class="currency">$</span>
                                <span class="amount">16.000 COP</span>
                                <br>
                                <span class="currency">$</span>
                                <span class="month">16.000 COP {{ 'work.amount' | translate }}</span> -->
                                <br>
                                <li><a href="https://wa.me/573106711110?text={{ 'work.whatsapp_products_1' | translate }}" target="_blank" class="btn btn-primary">{{ 'work.buy' | translate}}</a></li>
                            </ul>
                        </div>
                        <a href="assets/img/prod_img_6.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix design ">
                    <div class="single-work">
                        <img src="assets/img/prod_img_3.jpg" alt="work-img">

                        <div class="work-content">
                            <h3>2 {{ 'work.subtitle_2' | translate }}</h3>
                            <ul>
                                <!-- <span class="currency">$</span>
                                <span class="amount">31.650 COP</span>
                                <br>
                                <span class="currency">$</span>
                                <span class="month">15.825 COP {{ 'work.amount' | translate }}</span> -->
                                <br>
                                <li><a href="https://wa.me/573106711110?text={{ 'work.whatsapp_products_2' | translate }}" target="_blank" class="btn btn-primary">{{ 'work.buy' | translate}}</a></li>
                            </ul>
                        </div>

                        <a href="assets/img/prod_img_3.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix graphic ">
                    <div class="single-work">
                        <img src="assets/img/prod_img_5.jpg" alt="work-img">

                        <div class="work-content">
                            <h3>10 {{ 'work.subtitle_2' | translate }}</h3>
                            <ul>
                                <!-- <span class="currency">$</span>
                                <span class="amount">156.700 COP</span>
                                <br>
                                <span class="currency">$</span>
                                <span class="month">15.670 COP {{ 'work.amount' | translate }}</span> -->
                                <br>
                                <li><a href="https://wa.me/573106711110?text={{ 'work.whatsapp_products_3' | translate }}" target="_blank" class="btn btn-primary">{{ 'work.buy' | translate}}</a></li>
                            </ul>
                        </div>

                        <a href="assets/img/prod_img_5.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix photoshop ">
                    <div class="single-work">
                        <img src="assets/img/prod_img_4.jpg" alt="work-img">

                        <div class="work-content">
                            <h3>20 {{ 'work.subtitle_2' | translate }}</h3>
                            <ul>
                                <!-- <span class="currency">$</span>
                                <span class="amount">311.250 COP</span>
                                <br>
                                <span class="currency">$</span>
                                <span class="month">15.563 COP {{ 'work.amount' | translate }}</span> -->
                                <br>
                                <li><a href="https://wa.me/573106711110?text={{ 'work.whatsapp_products_4' | translate }}" target="_blank" class="btn btn-primary">{{ 'work.buy' | translate}}</a></li>
                            </ul>
                        </div>

                        <a href="assets/img/prod_img_4.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix illustrator">
                    <div class="single-work">
                        <img src="assets/img/prod_img_2.jpg" alt="work-img">

                        <div class="work-content">
                            <h3>200 {{ 'work.subtitle_2' | translate }}</h3>
                            <ul>
                                <!-- <span class="currency">$</span>
                                <span class="amount">3.214.400 COP</span>
                                <br>
                                <span class="currency">$</span>
                                <span class="month">15.454 COP {{ 'work.amount' | translate }}</span> -->
                                <br>
                                <li><a href="https://wa.me/573106711110?text={{ 'work.whatsapp_products_5' | translate }}" target="_blank" class="btn btn-primary">{{ 'work.buy' | translate}}</a></li>
                            </ul>
                        </div>

                        <a href="assets/img/prod_img_2.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
