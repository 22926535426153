import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class UseLanguageService {
    lang: string;

    constructor(public translate: TranslateService) {
        this.setDefaultTranslation();
    }

    private setDefaultTranslation(): void {
        if (["en", "es"].indexOf(this.translate.getBrowserLang()) > -1) {
            this.lang = this.translate.getBrowserLang();
            this.translate.setDefaultLang(this.translate.getBrowserLang());
        } else {
            this.lang = "en";
            this.translate.setDefaultLang(this.lang);
        }
    }

    changeLanguage(language: string): void {
        this.translate.use(language);
    }
}
