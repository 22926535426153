import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { TeamComponent } from './components/common/team/team.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { WorkComponent } from './components/common/work/work.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PartnerComponent } from './components/common/partner/partner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        CtaComponent,
        TeamComponent,
        FooterComponent,
        FaqComponent,
        WorkComponent,
        PartnerComponent,
        ContactComponent,
        HomeTwoComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoader,
              deps: [HttpClient]
            }
          })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }