<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/">Biorganicol</a></h3>
        <ul>
            <li>
                <a href="https://www.facebook.com/Biorganicol" target="_blank" class="fab fa-facebook-f"></a>
            </li>
            <li>
                <a href="https://www.instagram.com/biorganicol/" target="_blank" class="fab fa-instagram"></a>
            </li>
            <!--<li><a href="#" class="fab fa-twitter"></a></li>
            <li><a href="#" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" class="fab fa-skype"></a></li>-->
        </ul>
        <p>Copyright <i class="far fa-copyright"></i> {{ year }} {{ 'footer.copyright' | translate }}</p>
    </div>
</footer>
<div class='whatsapp'>
    <div class="separator" style="clear: both; text-align: left;">
    </div>
    <div class="separator" style="clear: both; text-align: center;">
    </div>
    <div class="separator" style="clear: both; text-align: center;">
    </div>
    <div class="separator" style="clear: both; text-align: left;">
        <a href="https://wa.me/573106711110?text={{ 'footer.whatsappMessage' | translate }}" style="clear: left; float: left; margin-bottom: 1em; margin-right: 5px;" target="_blank"><img border="0" data-original-height="59" data-original-width="59" src="../../../../assets/img/btn_whatsapp.png" /></a>
    </div>
    <!-- <div class="separator" style="clear: both; text-align: center;">
        <a href="https://m.me/robin.campo"
            style="clear: left; float: left; margin-bottom: 1em; margin-right: 5px;" target="_blank"><img border="0"
                data-original-height="59" data-original-width="59"
                src="../../../../assets/img/facebook messenger icono.png" /></a>
    </div>-->
</div>

<div class="go-top"><i class="fas fa-chevron-up"></i></div>