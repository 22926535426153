<section id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>{{'welcome.innovation' | translate}}</h4>
            <h2>{{'welcome.titleOne' | translate}} <span>{{'welcome.titleTwo' | translate}}</span></h2>
            <p>{{'welcome.content' | translate}}</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-seedling"></i>
                    </div>
                    <h3>{{'welcome.cardAboutTitle' | translate}}</h3>
                    <!--<button class="card-btn" (click)="showAbout()"><i class="fa fa-arrow-right"></i></button>-->
                    <a class="popup-content link-btn" href="#about-modal"><i class="fa fa-arrow-right"></i></a>
                    <div id="about-modal" class="mfp-hide white-popup">
                        <h1>{{'welcome.cardAboutTitle' | translate}}</h1>
                        <br>
                        <p>{{'welcome.cardAboutContent' | translate}}</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-bullseye"></i>
                    </div>
                    <h3>{{'welcome.cardMissionTitle' | translate}}</h3>
                    <a href="#mission-modal" class="link-btn popup-content"><i class="fa fa-arrow-right"></i></a>
                    <div id="mission-modal" class="mfp-hide white-popup">
                        <h1>{{'welcome.cardMissionTitle' | translate}}</h1>
                        <br>
                        <p>{{'welcome.cardMissionContent' | translate}}</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-eye"></i>
                    </div>
                    <h3>{{'welcome.cardVisionTitle' | translate}}</h3>
                    <a href="#vision-modal" class="link-btn popup-content"><i class="fa fa-arrow-right"></i></a>
                    <div id="vision-modal" class="mfp-hide white-popup">
                        <h1>{{'welcome.cardVisionTitle' | translate}}</h1>
                        <br>
                        <p>{{'welcome.cardVisionContent' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>