<section id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'team.title_part_1' | translate }} <span>{{ 'team.title_part_2' | translate }}</span></h2>
            <p>{{ 'team.subtitle' | translate }}</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">

                <div class="image">
                    <img src="assets/img/card-papaya.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">{{ 'team.case_1' | translate }}</h3>
                    <span class="post">{{ 'team.place_1' | translate }}</span>
                </div>

                <div class="social">
                    <ul>
                        <div class="video-btn">
                            <a href="assets/video/cultivo-papaya.mp4" class="popup-youtube"><i class="fas fa-play"></i></a>
                        </div>
                    </ul>
                </div>

            </div>

            <div class="single-team-box">

                <div class="image">
                    <img src="assets/img/card-tomate.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">{{ 'team.case_2' | translate }}</h3>
                    <span class="post">{{ 'team.place_2' | translate }}</span>
                </div>

                <div class="social">
                    <ul>
                        <div class="video-btn">
                            <a href="assets/video/cultivo-tomate.mp4" class="popup-youtube"><i class="fas fa-play"></i></a>
                        </div>
                    </ul>
                </div>

            </div>

            <div class="single-team-box">

                <div class="image">
                    <img src="assets/img/card-guanabana.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">{{ 'team.case_3' | translate }}</h3>
                    <span class="post">{{ 'team.place_3' | translate }}</span>
                </div>

                <div class="social">
                    <ul>
                        <div class="video-btn">
                            <a href="assets/video/cultivo-guanabana.mp4" class="popup-youtube"><i class="fas fa-play"></i></a>
                        </div>
                    </ul>
                </div>

            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>