<section class="cta-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>{{ 'cta.title' | translate }}</h4>
                <h2>{{ 'cta.subtitle' | translate }}</h2>
                <p>{{ 'cta.paragram' | translate }}</p>
                <a href="#contact" class="btn btn-primary">{{ 'cta.btn-contact' | translate }}</a>
                <a href="#work" class="btn btn-primary view-work">{{'cta.btn-product' | translate }}</a>
            </div>
        </div>
    </div>
</section>