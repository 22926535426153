import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UseLanguageService } from '../services/i18n/use-language.service';

declare const $: any;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  lang: string;

  constructor(
    public translate: TranslateService,
    public useLanguageService: UseLanguageService
  ) { }

  ngOnInit() {
    this.lang = this.useLanguageService.lang;
  }
}
