<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div class="container">
        <a class="navbar-brand logo" routerLink="/"><img src="../../../../assets/img/logo.png"></a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link active" href="#home">{{'navbar.home' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#welcome">{{'navbar.about' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#work">{{'navbar.products' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#team">{{'navbar.team' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#faq">{{'navbar.questions' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">{{'navbar.contact' | translate}}</a></li>
                <li class="btn-item"><button class="nav-link" (click)="switchLang(lang === 'en' ? 'es' : 'en')">{{ lang === 'es' ? 'ENGLISH' : 'ESPAÑOL'}}</button></li>
            </ul>
        </div>
    </div>
</nav>